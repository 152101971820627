import React from 'react';
import { MarkerClustererF, MarkerF } from '@react-google-maps/api';
import {ClusterIconStyle, MarkerExtended} from "@react-google-maps/marker-clusterer";
import settings from "../../settings";
import { Pin } from '@ampeco/charge-models';
import colors from "../../res/colors";
import { splitGeoToLatLng } from '../../utils/Map';

const clustererOptions = {
    enableRetinaIcons: true,
    averageCenter: true,
    minimumClusterSize: 5,
    batchSize: 100,
    clusterClass: 'maps-clusterer',
    imagePath: `${settings.images}/pin-multiple-`,
    imageSizes: [42, 60],
    calculator: function CALCULATOR(
        markers: MarkerExtended[],
    ): ClusterIconInfo {
        const title = '';
        const count = markers.length.toString();
        const parsedCount = parseInt(count);
        let text = count;
        switch (true) {
            case parsedCount <= 10: {
                text = count;
                break;
            }
            case (parsedCount > 10 && parsedCount <= 50): {
                text = '10+';
                break;
            }
            case (parsedCount > 50 && parsedCount < 100): {
                text = '50+';
                break;
            }
            case (parsedCount >= 100): {
                text = '99+';
                break;
            }
        }

        return {
            text,
            index: 1,
            title
        };
    }
};

const clustererStyles: ClusterIconStyle[] = [
    {
        url: `${settings.images}/pin-multiple.png`,
        height: 55,
        width: 45,
        textColor: colors.pinTextColor,
        textSize: 16,
        anchorText: [-8, 0]
    }
];

interface Props {
    pins: Pin[];
    onMarkerClick: (pin: Pin) => void;
}

const MapClusterComponent = ({ pins, onMarkerClick }: Props) => {
    return (
        <MarkerClustererF
            key="MarkerClustererF"
            options={clustererOptions}
            styles={clustererStyles}
        >
            {(clusterer) => (
                pins.map((pin: Pin) => {
                    const { latitude: lat, longitude: lng } = splitGeoToLatLng(pin.geo);

                    return (
                        <MarkerF
                            key={pin.id}
                            position={{lat, lng}}
                            clusterer={clusterer}
                            icon={{
                                url: settings.images + '/pin-single.png',
                            }}
                            onClick={() => {
                                onMarkerClick(pin);
                            }}
                        />
                    )
                })
            )}
        </MarkerClustererF>
    );
};

export default MapClusterComponent;
