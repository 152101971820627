import React from 'react';
import { WarningNotice } from './index';
import { StyleSheet, Text } from 'react-native';
import {__} from "../../i18n";

export default () => {
    return (
        <WarningNotice onMap style={styles.container}>
            <Text>
                {__('notice.pins-are-loading')}
            </Text>
        </WarningNotice>
    );
}

const styles = StyleSheet.create({
    container: {
        width: 'auto',
    }
});
