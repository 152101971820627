import React from 'react';
import {ImageStyle, StyleSheet, Text, ViewStyle} from 'react-native';
import Notice from '@ampeco/notice';
import {ButtonIcon} from '@ampeco/icons';
import {ThemeConsumer} from 'react-native-elements';

interface Props extends React.PropsWithChildren {
    style?: Partial<ViewStyle>;
    iconStyle?: ImageStyle;
    onPress?: () => void;
    text?: string;
    onMap?: boolean;
}

export default class WarningNotice extends React.Component<Props> {
    render() {
        return <ThemeConsumer>{({theme}) => {
            return <Notice
                style={StyleSheet.flatten([styles.containerStyle, this.props.onMap ? theme.Notice.mapTextStyle : theme.Notice.textStyle, this.props.style])}
                onPress={this.props.onPress}
            >
                <ButtonIcon
                    source={theme.Notice.buttonIcon.source} style={StyleSheet.flatten([styles.iconStyle, this.props.onMap ? theme.Notice.mapIconStyle : theme.Notice.iconStyle, this.props.iconStyle])}
                />
                {this.props.text && <Text style={this.props.onMap ? theme.Notice.mapTextStyle : theme.Notice.textStyle}>
                    {this.props.text}
                </Text>}
                {this.props.children}
            </Notice>;
        }}</ThemeConsumer>;
    }
}

const styles = StyleSheet.create({
    containerStyle: {
        marginBottom: 5,
        padding: 8,
    },
    iconStyle: {
        marginRight: 8,
    },
});
